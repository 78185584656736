.orderButtonIcon {
  width: 30px;
  height: 30px;
  background: transparent no-repeat center center;
  background-size: cover;
  margin-right: 10px;
}

.orderButton {
  text-decoration: none;
  text-align: center;
  outline: none;
  background: #f37280;
  border: none;
  padding: 10px;
  border-radius: 6px;
  width: 100%;
  display: flex;
  color: #fff;
  align-items: center;
  justify-content: center;
}

.copyText {
  color: #8c8c8c;
  text-align: center;
}

.orderButtonCopy {
  height: 44px;
  cursor: copy;
  justify-content: center;
  align-items: center;
}

.orderButton:visited {
  background: #f37280;
}

.orderButton:hover,
.orderButton:disabled,
.orderButton:active {
  background: #da5e6b;
}

.copy {
  display: flex;
}

.copy textarea {
  caret-color: transparent;
}


.copyMessage {
  cursor: copy;
  user-select: text;
  width: 100%;
  background: #f6f8fe;
  padding: 10px 30px 20px 20px;
  border-radius: 20px 20px 20px 2px;
  font-size: 17px;
  line-height: 25px;
  margin-left: 10px;
  position: relative;
}

.copyIcon {
  position: absolute;
  right: 10px;
  top: 10px;
}