.select {
  outline: none;
  padding: 5px 6px;
  font-size: 14px;
  border-radius: 5px;
  border: 1px solid #d9d9d9;
  width: 100%;
}

.selectEmpty {
  color: #bfbfbf;
}