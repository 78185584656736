.cell {
  left: 0;
  right: 0;
  background: #fff;
  border-radius: 4px;
  top: 0;
  bottom: 0;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 5px;
}

.cellSelected {
  background: #f37280;
  color: #fff;
  font-size: 17px;
}

.cellVacation {
  background: #ffecf2;
  pointer-events: all !important;
  cursor: not-allowed;
}