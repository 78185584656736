.common {
  font-size: 30px;
  line-height: 30px;
  display: flex;
  padding: 15px 0;
}

.commonWeight {
  width: 105px;
}

.commonPeople {
  font-size: 30px;
  line-height: 30px;
}

.slider:global(.ant-slider-horizontal.ant-slider-with-marks) {
  margin-bottom: 11px;
  width: 100%;
}

.buttonType {
  outline: none;
  display: inline-block;
  background: #fff;
  border: 1px solid #c06b84;
  font-size: 13px;
  line-height: 100%;
  padding: 4px 7px;
  border-radius: 10px;
  color: #c06b84;
  margin-right: 5px;
  margin-top: 5px;
  cursor: pointer;
}

.buttonTypeSelected {
  background: #c06b84;
  color: #fff;
}

.buttonType:last-child {
  margin-right: 0;
}

.buttonPlusWrapper {
  display: flex;
  padding: 0 0 10px;
  align-items: center;
  justify-content: center;
}

.buttonPlus {
  cursor: pointer;
  color: #91caff;
  outline: none;
  background: #fff;
  border: none;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  font-size: 26px;
  line-height: 26px;
}

.buttonPlus:hover,
.buttonPlus:active {
  color: #1577ff;
}