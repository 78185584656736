.order {
  text-align: center;
  padding: 10px 20px;
  background: #f37280;
  width: 100%;
  display: block;
  text-decoration: none;
  border-radius: 10px;
  color: #fff;
  font-size: 20px;
}

.wrapper {
  text-align: center;
}

.icons > * {
  padding: 0 4px;
}