.comment {
  margin-top: 20px;
}

.wrapper {
  padding: 15px 0;
}

.cover {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  background: #ffecf2;
  border-radius: 7px;
  font-size: 19px;
  flex-direction: column;
  cursor: pointer;
}

.coverIcon {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  position: relative;
  background: rgb(255,202,0);
  background: linear-gradient(90deg, rgba(255,202,0,1) 0%, rgba(255,45,66,1) 49%, rgba(211,0,197,1) 100%);
}

.coverIcon:after {
  content: ' ';
  position: absolute;
  background: url('./Delivery/mapIcon.png') no-repeat center center;
  background-color: #fff;;
  background-size: cover;
  transform: scale(0.9);
  border: 3px solid #fff;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  border-radius: inherit;
}

.coverName {
  padding: 7px 0 5px;
}