.screen {
  padding: 15px;

  &_flexible {
    display: flex;
    flex-direction: column;

    &Center {
      justify-content: center;
    }
  }

  &_bottomOffset {
    padding-bottom: 70px;
  }
}