.row {
  display: flex;
  color: #302244;
}

.rowContent {
  flex-shrink: 1;
  flex-basis: 100%;
  padding: 0 16px;
}

.rowContentChild {
  padding: 10px 0 30px 0;
}

.rowProgress {
  flex-grow: 0;
  padding-top: 3px;
  display: flex;
  flex-flow: column;
}

.rowContentH2 {
  font-size: 14px;
}

.rowContentH1 {
  font-weight: bold;
  font-size: 18px;
  margin-top: 4px;
}

.rowProgressLine {
  height: 100%;
  min-height: 50px;
  display: flex;
  justify-content: center;
  margin: 10px 0;
  overflow: hidden;
}

.rowProgressLine:after {
  content: ' ';
  width: 5px;
  height: 100%;
  transition: 0.5s ease-in transform;
  transform: translateY(-100%);
  background: #f37280;
  border-radius: 4px;
}

.rowProgressLineFilled:after {
  transform: translateY(0);
}

.rowProgressIcon {
  width: 30px;
  height: 30px;
}